import * as React from 'react'
import './segmentation.css'

export const SegmentationIcon = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" width={props.svgwidth} height={props.svgheight} className="panel-icon" viewBox="0 0 33.653 29.123">
  <g id="Group_6470" data-name="Group 6470" transform="translate(-40.266 -515.781)">
    <g id="Group_6469" data-name="Group 6469" transform="translate(-2.2 -1.904)">
      <g id="Group_6468" data-name="Group 6468" transform="translate(42.466 517.685)">
        <path id="Path_21795" data-name="Path 21795" className="seg-1" d="M385.526,308.926H358.388a.715.715,0,0,0-.487.582.594.594,0,0,0,.036.192h0l-.021,1.979a.692.692,0,0,0,1.384,0v-1.434h11.956l-.015,1.434a.692.692,0,1,0,1.384,0v-1.434h11.92v1.434a.692.692,0,0,0,1.384,0v-2.126A.691.691,0,0,0,385.526,308.926Z" transform="translate(-355.028 -293.595)"/>
        <g id="Group_6464" data-name="Group 6464" transform="translate(0 20.739)">
          <path id="Path_21268" data-name="Path 21268" className="seg-2" d="M355.717,344.1a1.148,1.148,0,0,1-.394-.861,1.163,1.163,0,0,1,1.16-1.163,1.094,1.094,0,0,1,.215.043,1.148,1.148,0,1,0-.98,1.978Z" transform="translate(-352.493 -341.023)"/>
          <path id="Path_21270" data-name="Path 21270" className="seg-2" d="M348.093,366.2a2.709,2.709,0,0,1,2.511-1.675,2.783,2.783,0,0,1,.8.118,2.691,2.691,0,0,0-3.871,1.292,3.874,3.874,0,0,0,1.015.562A3.69,3.69,0,0,1,348.093,366.2Z" transform="translate(-346.619 -359.228)"/>
          <path id="Path_21272" data-name="Path 21272" className="seg-3" d="M357.493,344.363a.673.673,0,0,0,.605.9,1.165,1.165,0,0,0,1.164-1.164,1.148,1.148,0,0,0-.394-.857,1.073,1.073,0,0,0-.215-.043A1.163,1.163,0,0,0,357.493,344.363Z" transform="translate(-354.665 -342.14)"/>
          <path id="Path_21273" data-name="Path 21273" className="seg-3" d="M350.5,367.343a3.723,3.723,0,0,0,.46.3,4.162,4.162,0,0,0,4.018-.562,2.7,2.7,0,0,0-1.16-1.292,2.779,2.779,0,0,0-.8-.118A2.709,2.709,0,0,0,350.5,367.343Z" transform="translate(-349.021 -360.369)"/>
          <path id="Path_21274" data-name="Path 21274" className="seg-1" d="M352.456,341.5a1.961,1.961,0,1,0-1.959-1.964h0A1.965,1.965,0,0,0,352.456,341.5Zm0-3.125a1.191,1.191,0,0,1,1.164,1.162,1.166,1.166,0,0,1-1.164,1.164,1.331,1.331,0,0,1-1.16-1.164A1.163,1.163,0,0,1,352.456,338.379Z" transform="translate(-349.022 -337.581)"/>
          <path id="Path_21275" data-name="Path 21275" className="seg-1" d="M349.557,362.767l-.072-.243a3.525,3.525,0,0,0-6.734,0l-.072.243.19.171a4.859,4.859,0,0,0,6.5,0Zm-3.444-1.927a2.815,2.815,0,0,1,2.517,1.675,4.161,4.161,0,0,1-4.018.562,3.875,3.875,0,0,1-1.015-.562,2.709,2.709,0,0,1,2.516-1.676Z" transform="translate(-342.68 -355.802)"/>
        </g>
        <g id="Group_6465" data-name="Group 6465" transform="translate(13.388 20.739)">
          <path id="Path_21268-2" data-name="Path 21268" className="seg-2" d="M426.655,344.1a1.148,1.148,0,0,1-.394-.861,1.163,1.163,0,0,1,1.16-1.163,1.094,1.094,0,0,1,.215.043,1.148,1.148,0,1,0-.98,1.978Z" transform="translate(-423.431 -341.023)"/>
          <path id="Path_21270-2" data-name="Path 21270" className="seg-2" d="M419.03,366.2a2.709,2.709,0,0,1,2.511-1.675,2.783,2.783,0,0,1,.8.118,2.692,2.692,0,0,0-3.871,1.292,3.878,3.878,0,0,0,1.015.562A3.709,3.709,0,0,1,419.03,366.2Z" transform="translate(-417.556 -359.228)"/>
          <path id="Path_21272-2" data-name="Path 21272" className="seg-3" d="M428.431,344.363a.673.673,0,0,0,.6.9A1.165,1.165,0,0,0,430.2,344.1a1.148,1.148,0,0,0-.394-.857,1.076,1.076,0,0,0-.215-.043A1.163,1.163,0,0,0,428.431,344.363Z" transform="translate(-425.602 -342.14)"/>
          <path id="Path_21273-2" data-name="Path 21273" className="seg-3" d="M421.434,367.343a3.719,3.719,0,0,0,.46.3,4.162,4.162,0,0,0,4.019-.562,2.7,2.7,0,0,0-1.16-1.292,2.777,2.777,0,0,0-.8-.118A2.709,2.709,0,0,0,421.434,367.343Z" transform="translate(-419.959 -360.369)"/>
          <path id="Path_21274-2" data-name="Path 21274" className="seg-1" d="M423.394,341.5a1.961,1.961,0,1,0-1.959-1.964h0A1.965,1.965,0,0,0,423.394,341.5Zm0-3.125a1.191,1.191,0,0,1,1.164,1.162,1.166,1.166,0,0,1-1.164,1.164,1.331,1.331,0,0,1-1.16-1.164A1.163,1.163,0,0,1,423.394,338.379Z" transform="translate(-419.96 -337.581)"/>
          <path id="Path_21275-2" data-name="Path 21275" className="seg-1" d="M420.494,362.767l-.072-.243a3.525,3.525,0,0,0-6.734,0l-.072.243.19.171a4.859,4.859,0,0,0,6.5,0Zm-3.444-1.927a2.815,2.815,0,0,1,2.517,1.675,4.162,4.162,0,0,1-4.018.562,3.871,3.871,0,0,1-1.015-.562,2.709,2.709,0,0,1,2.516-1.676Z" transform="translate(-413.617 -355.802)"/>
        </g>
        <g id="Group_6466" data-name="Group 6466" transform="translate(26.776 20.739)">
          <path id="Path_21268-3" data-name="Path 21268" className="seg-2" d="M497.592,344.1a1.148,1.148,0,0,1-.394-.861,1.163,1.163,0,0,1,1.16-1.163,1.1,1.1,0,0,1,.215.043,1.148,1.148,0,1,0-.98,1.978Z" transform="translate(-494.369 -341.023)"/>
          <path id="Path_21270-3" data-name="Path 21270" className="seg-2" d="M489.968,366.2a2.709,2.709,0,0,1,2.512-1.675,2.783,2.783,0,0,1,.8.118,2.693,2.693,0,0,0-3.871,1.292,3.878,3.878,0,0,0,1.015.562A3.721,3.721,0,0,1,489.968,366.2Z" transform="translate(-488.494 -359.228)"/>
          <path id="Path_21272-3" data-name="Path 21272" className="seg-3" d="M499.369,344.363a.673.673,0,0,0,.6.9,1.165,1.165,0,0,0,1.164-1.164,1.148,1.148,0,0,0-.394-.857,1.076,1.076,0,0,0-.215-.043A1.163,1.163,0,0,0,499.369,344.363Z" transform="translate(-496.54 -342.14)"/>
          <path id="Path_21273-3" data-name="Path 21273" className="seg-3" d="M492.372,367.343a3.712,3.712,0,0,0,.46.3,4.162,4.162,0,0,0,4.018-.562,2.7,2.7,0,0,0-1.16-1.292,2.776,2.776,0,0,0-.8-.118A2.709,2.709,0,0,0,492.372,367.343Z" transform="translate(-490.897 -360.369)"/>
          <path id="Path_21274-3" data-name="Path 21274" className="seg-1" d="M494.331,341.5a1.961,1.961,0,1,0-1.959-1.964h0A1.965,1.965,0,0,0,494.331,341.5Zm0-3.125a1.19,1.19,0,0,1,1.164,1.162,1.165,1.165,0,0,1-1.164,1.164,1.33,1.33,0,0,1-1.16-1.164A1.163,1.163,0,0,1,494.331,338.379Z" transform="translate(-490.897 -337.581)"/>
          <path id="Path_21275-3" data-name="Path 21275" className="seg-1" d="M491.432,362.767l-.072-.243a3.525,3.525,0,0,0-6.734,0l-.072.243.19.171a4.859,4.859,0,0,0,6.5,0Zm-3.444-1.927a2.815,2.815,0,0,1,2.517,1.675,4.162,4.162,0,0,1-4.019.562,3.876,3.876,0,0,1-1.015-.562,2.709,2.709,0,0,1,2.517-1.676Z" transform="translate(-484.555 -355.802)"/>
        </g>
        <g id="Group_6467" data-name="Group 6467" transform="translate(11.543 0)">
          <path id="Path_21269" data-name="Path 21269" className="seg-2" d="M422.382,228.411a1.68,1.68,0,0,1-.577-1.26,1.7,1.7,0,0,1,1.7-1.7,1.522,1.522,0,0,1,.315.063,1.68,1.68,0,1,0-1.435,2.894Z" transform="translate(-417.662 -223.901)"/>
          <path id="Path_21271" data-name="Path 21271" className="seg-2" d="M409.04,267.527a3.959,3.959,0,0,1,3.676-2.451,4.055,4.055,0,0,1,1.171.173,3.937,3.937,0,0,0-5.664,1.891,5.63,5.63,0,0,0,1.484.822A5.3,5.3,0,0,1,409.04,267.527Z" transform="translate(-406.877 -257.321)"/>
          <path id="Path_21276" data-name="Path 21276" className="seg-4" d="M426.1,229.221a1.681,1.681,0,0,0,.577,1.26,1.742,1.742,0,0,0,.315.063,1.706,1.706,0,0,0,1.7-1.7,1.68,1.68,0,0,0-.58-1.255,1.528,1.528,0,0,0-.315-.063A1.7,1.7,0,0,0,426.1,229.221Z" transform="translate(-421.956 -225.968)"/>
          <path id="Path_21277" data-name="Path 21277" className="seg-4" d="M413.452,269.624a5.264,5.264,0,0,0,.671.439A6.092,6.092,0,0,0,420,269.24a3.928,3.928,0,0,0-1.7-1.891,4.057,4.057,0,0,0-1.171-.173A3.96,3.96,0,0,0,413.452,269.624Z" transform="translate(-411.288 -259.417)"/>
          <path id="Path_21278" data-name="Path 21278" className="seg-1" d="M416.32,223.322a2.87,2.87,0,1,0-2.867-2.874v0A2.875,2.875,0,0,0,416.32,223.322Zm0-4.573a1.747,1.747,0,0,1,1.7,1.7,1.707,1.707,0,0,1-1.7,1.7,1.745,1.745,0,0,1-.315-.063,1.68,1.68,0,0,1-1.387-1.64A1.7,1.7,0,0,1,416.32,218.749Z" transform="translate(-411.288 -217.581)"/>
          <path id="Path_21279" data-name="Path 21279" className="seg-1" d="M409.575,260.854a5.157,5.157,0,0,0-9.853,0l-.1.355.278.25a7.109,7.109,0,0,0,9.516,0l.278-.25Zm-4.928-2.465a4.121,4.121,0,0,1,3.682,2.451,6.092,6.092,0,0,1-5.881.822,5.634,5.634,0,0,1-1.483-.822,3.965,3.965,0,0,1,3.682-2.451Z" transform="translate(-399.617 -251.018)"/>
        </g>
      </g>
    </g>
  </g>
</svg>
