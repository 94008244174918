import * as React from 'react'
import './profilesettings.css'

export const ProfileSettings = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" id="Group_6292" className="panel-icon" data-name="Group 6292" viewBox="0 0 66.655 53.236">

    <g id="Group_6285" data-name="Group 6285">
        <g id="Group_6268" data-name="Group 6268">
            <g id="Group_6266" data-name="Group 6266">
                <path id="Path_12224" d="M545.372 630.413h-15.149a.866.866 0 0 0-.866.865V642.1a.866.866 0 0 0 .866.866h8.377a2.594 2.594 0 1 0 0-1.731h-7.512v-9.089h14.283a5.633 5.633 0 0 1 5.629 5.625v36.79a5.633 5.633 0 0 1-5.627 5.627h-51.94a5.633 5.633 0 0 1-5.627-5.627v-36.79a5.633 5.633 0 0 1 5.627-5.627h23.526a2.6 2.6 0 1 0 0-1.731h-23.526a7.366 7.366 0 0 0-7.358 7.358v36.79a7.366 7.366 0 0 0 7.358 7.358h51.939a7.367 7.367 0 0 0 7.358-7.358v-36.79a7.366 7.366 0 0 0-7.358-7.358z" className="profile-settings-1" data-name="Path 12224" transform="translate(-486.075 -628.684)"/>
                <path id="Path_12225" d="M666.94 738.405h17.313a.866.866 0 1 0 0-1.731H666.94a.866.866 0 1 0 0 1.731z" className="profile-settings-1" data-name="Path 12225" transform="translate(-627.12 -713.304)"/>
                <path id="Path_12226" d="M699.924 776.674h-12.985a.866.866 0 0 0 0 1.731h12.985a.866.866 0 1 0 0-1.731z" className="profile-settings-1" data-name="Path 12226" transform="translate(-642.792 -744.647)"/>
                <path id="Path_12227" d="M699.924 816.674h-12.985a.866.866 0 0 0 0 1.731h12.985a.866.866 0 1 0 0-1.731z" className="profile-settings-1" data-name="Path 12227" transform="translate(-642.792 -775.991)"/>
            </g>
            <g id="Group_6267" data-name="Group 6267" transform="translate(6.041 12.056)">
                <path id="Path_12228" d="M548.946 773.346a9.8 9.8 0 0 0-7.027 6.6 14.1 14.1 0 0 0 1.515 1.17c-.118-.112-.239-.225-.355-.337a11.666 11.666 0 0 1 5.867-7.433z" className="profile-settings-2" data-name="Path 12228" transform="translate(-535.875 -754.095)"/>
                <path id="Path_12229" d="M563.291 715.508a5.716 5.716 0 0 1 4.347-5.182c-.048 0-.091-.014-.139-.014a4.9 4.9 0 0 0-.844 9.731 5.117 5.117 0 0 1-3.364-4.535z" className="profile-settings-2" data-name="Path 12229" transform="translate(-552.073 -704.702)"/>
                <path id="Path_12230" d="M534.493 690.871a14.04 14.04 0 0 0-14.024 14.029 13.866 13.866 0 0 0 3.551 9.311 11.212 11.212 0 0 1 20.945 0 13.865 13.865 0 0 0 3.552-9.311 14.04 14.04 0 0 0-14.024-14.029zm0 15.427a6.311 6.311 0 1 1 6.311-6.311 6.318 6.318 0 0 1-6.311 6.313z" className="profile-settings-2" data-name="Path 12230" transform="translate(-519.067 -689.469)"/>
                <path id="Path_12231" d="M574.943 715.271a4.906 4.906 0 0 0-4.77-4.894 5.717 5.717 0 0 0-4.347 5.182 5.117 5.117 0 0 0 3.364 4.536 4.845 4.845 0 0 0 5.753-4.823z" className="profile-settings-3" data-name="Path 12231" transform="translate(-554.608 -704.753)"/>
                <path id="Path_12232" d="M562.422 703.832a6.311 6.311 0 1 0 6.311 6.311 6.318 6.318 0 0 0-6.311-6.311zm-4.908 6.311a4.914 4.914 0 0 1 4.908-4.909c.048 0 .092.013.139.014a4.9 4.9 0 1 1-5.047 4.894z" className="profile-settings-1" data-name="Path 12232" transform="translate(-546.995 -699.625)"/>
                <path id="Path_12233" d="M555.5 771.875a9.75 9.75 0 0 0-2.355.318 11.666 11.666 0 0 0-5.866 7.433c.116.112.237.224.355.337a13.988 13.988 0 0 0 17.249-1.171 9.823 9.823 0 0 0-9.383-6.917z" className="profile-settings-3" data-name="Path 12233" transform="translate(-540.077 -752.942)"/>
                <path id="Path_12234" d="M544.842 699.818a15.426 15.426 0 0 0-30.853 0 15.262 15.262 0 0 0 4.475 10.842l-.006.024.3.285a15.375 15.375 0 0 0 19.765 1.291 15.538 15.538 0 0 0 1.552-1.291l.295-.281-.006-.024a15.265 15.265 0 0 0 4.478-10.846zm-15.427 14.024a14.333 14.333 0 0 1-9.382-3.6 9.821 9.821 0 0 1 18.764 0 13.963 13.963 0 0 1-9.381 3.6zm10.473-4.713a11.213 11.213 0 0 0-20.946 0 13.868 13.868 0 0 1-3.551-9.311 14.024 14.024 0 0 1 28.049 0 13.868 13.868 0 0 1-3.552 9.311z" className="profile-settings-1" data-name="Path 12234" transform="translate(-513.989 -684.391)"/>
            </g>
        </g>
    </g>
</svg>
