import * as React from 'react'
import './pocket.css'

export const PocketLogo = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg"
  width={props.svgwidth || 16}
  height={props.svgheight || 16}
  className="pocket-logo"
  viewBox="0 0 85 85"
>
  <path className="pocket-a" d="M72.83,34.55c-.11,3.07,0,6.19-.36,9.22-1.14,8.41-4.43,15.8-11.4,21a29,29,0,0,1-6.75,3.75c-4.31,1.68-8.77,3-13.5,2.66a24.29,24.29,0,0,1-4.73-.75A29,29,0,0,1,17.21,53.7,44.81,44.81,0,0,1,13,39.42c-.46-4.6-.68-9.22-.92-13.83a32.84,32.84,0,0,1,.06-5.34,4,4,0,0,1,3.42-3.86,39.49,39.49,0,0,1,4.73-.62c4.42-.32,8.84-.57,13.29-.77,4.18-.23,8.42-.31,12.55-.49,3.57-.16,7.14-.46,10.71-.6,3-.12,6-.17,9.06-.11a5.55,5.55,0,0,1,5.21,3.52,10.83,10.83,0,0,1,1,3.34c.38,4.61.63,9.23.91,13.83Zm-30,8.31c-.58-.48-1.12-.87-1.58-1.33-2.43-2.46-4.8-5-7.27-7.39A25.92,25.92,0,0,0,30.11,31a4.12,4.12,0,0,0-5.11.5c-1.19,1.26-1.27,3-.11,4.76a15,15,0,0,0,1.7,2.18c3.29,3.34,6.57,6.7,10,9.94q2.23,1.93,4.64,3.64a3.11,3.11,0,0,0,4,0,22.86,22.86,0,0,0,3.08-2.63q3.93-4.2,7.73-8.48c1.31-1.47,2.58-3,3.74-4.59,1.39-1.91,1-4-.93-5.77-1.32-1.23-2.71-1.11-4.62.45a19,19,0,0,0-1.93,1.77c-3.16,3.28-6.25,6.57-9.49,10.11Z"/>
  <rect className="pocket-b" width="85" height="85"/>
</svg>