import * as React from 'react'
import './planicon.css'

export const Planicon = (props: any) =>
<div className="changeplanicon">
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28.024 28.542">
  <defs/>
  <g data-name="Group 6573">
    <g data-name="Group 6565">
      <g data-name="Group 6564">
        <path fill="#f1faf9" d="M27.665 14.31a14.283 14.283 0 00-.221-3.634 13.861 13.861 0 00-.707-1.688 14.821 14.821 0 00-.5-1.8 14.425 14.425 0 00-.952-1.6c-.369-.49-1.121-.635-1.55-1.071a13.892 13.892 0 00-1.223-1.354 14.282 14.282 0 00-1.424-1.161c-.524-.307-1.241-.184-1.8-.422A13.2 13.2 0 0017.63.912c-.584-.158-1.1-.72-1.694-.8a1.309 1.309 0 00-.165-.011c-.546 0-1.115.323-1.676.323S12.927 0 12.368 0a1.064 1.064 0 00-.126.008 14.226 14.226 0 00-1.735.7 13.724 13.724 0 00-1.788.418A9.844 9.844 0 005.698 3.2a14.566 14.566 0 00-1.56 1c-.43.436-.551 1.144-.92 1.635a13.819 13.819 0 00-1.127 1.438 14.262 14.262 0 00-.55 1.758 13.62 13.62 0 00-.638 1.691 13.968 13.968 0 00-.587 1.748 14.534 14.534 0 00.046 1.843c0 .625-.42 1.276-.342 1.881a14.363 14.363 0 00.441 1.829c.159.6.688 1.069.923 1.638s.081 1.35.383 1.88a14.783 14.783 0 00.994 1.605c.368.491 1.25.537 1.68.973s.508 1.288.989 1.663 1.21.409 1.739.719a13.429 13.429 0 001.668.707c.559.234 1.014.791 1.6.952a14.141 14.141 0 001.811.371 1.1 1.1 0 00.141.009c.554 0 1.135-.388 1.7-.388a14.057 14.057 0 001.81.118 13.208 13.208 0 001.772-.379 13.366 13.366 0 001.609-.852 13.685 13.685 0 001.7-.6 13.894 13.894 0 001.586-.909 14.181 14.181 0 001.221-1.371c.429-.436 1.263-.53 1.633-1.019a15.631 15.631 0 00.816-1.708 14.261 14.261 0 00.774-1.687 14.519 14.519 0 00.775-1.71 14.986 14.986 0 00.232-1.865c.076-.602-.352-1.234-.352-1.86z" data-name="Path 21913"/>
        <path fill="#001927" d="M17.95 6.387l.8 4.2c-.056.038-.1.091-.13.084-2.47-.629-4.849 1.663-4.243 4.612.434 2.115.814 4.241 1.215 6.363.046.243.071.491.119.836l-4.636.883L8.224 8.389l4.538-.864.345 1.316a19.923 19.923 0 011.611-1.288 17.925 17.925 0 011.731-.883z" data-name="Path 21914"/>
      </g>
    </g>
  </g>
</svg>
</div>