import * as React from 'react'
import './rssfeed.css'

export const RSSFeedIcon = (props: any) =>
<svg id="Component_20_5" data-name="Component 20 – 5" xmlns="http://www.w3.org/2000/svg"
  width={props.svgwidth} height={props.svgheight} className="rss-feed-icon" viewBox="0 0 31.188 34.541">
  <path id="Path_422" data-name="Path 422" className="feed-1" d="M158.452,272.645a.85.85,0,0,1,.872.825.8.8,0,0,1-.254.581.8.8,0,0,0,.258-.585.85.85,0,0,0-.872-.825h-7.832a.894.894,0,0,0-.618.244.89.89,0,0,1,.614-.24Z" transform="translate(-136.695 -263.089)"/>
  <path id="Path_423" data-name="Path 423" className="feed-1" d="M140.727,289.44a.85.85,0,0,1,.872-.824h5.222a.849.849,0,0,1,.871.824v0a.85.85,0,0,0-.872-.825H141.6a.849.849,0,0,0-.872.825Z" transform="translate(-135.507 -265.882)"/>
  <path id="Path_424" data-name="Path 424" className="feed-1" d="M157.268,276.634H141.6a.849.849,0,0,0-.872.825v0a.85.85,0,0,1,.872-.824h15.663a.85.85,0,0,1,.872.825.8.8,0,0,1-.255.583.8.8,0,0,0,.262-.588A.85.85,0,0,0,157.268,276.634Z" transform="translate(-135.507 -263.789)"/>
  <path id="Path_425" data-name="Path 425" className="feed-1" d="M143.629,270.44a.874.874,0,0,1-.8.5h-6.092v0h6.091A.873.873,0,0,0,143.629,270.44Z" transform="translate(-134.995 -262.705)"/>
  <path id="Path_426" data-name="Path 426" className="feed-1" d="M141.6,280.631h15.663a.85.85,0,0,1,.872.825.8.8,0,0,1-.255.583.8.8,0,0,0,.262-.587.85.85,0,0,0-.872-.825H141.6a.849.849,0,0,0-.872.825v0A.85.85,0,0,1,141.6,280.631Z" transform="translate(-135.507 -264.486)"/>
  <path id="Path_427" data-name="Path 427" className="feed-2" d="M143.7,268.828a.785.785,0,0,1-.07.323.873.873,0,0,1-.8.5h-6.091v24.687H143.1v.045h18.048v-.844h-1.44a1.8,1.8,0,0,1-1.235-.487,1.629,1.629,0,0,1-.385-.562c-.033.035-.058.073-.092.106a3.5,3.5,0,0,1-4.764-.016,3.049,3.049,0,0,1,.013-4.473c.038-.035.081-.06.119-.093a1.653,1.653,0,0,1-1.1-1.531v-2.2a1.545,1.545,0,0,0,.008-1.719v-.064H141.088a.849.849,0,0,1-.872-.825v0a.849.849,0,0,1,.872-.825h11.185v-.482a1.6,1.6,0,0,1,.5-1.159H141.088a.849.849,0,0,1-.872-.825v0a.849.849,0,0,1,.872-.825h15.669a.85.85,0,0,1,.872.825.8.8,0,0,1-.262.588.884.884,0,0,1-.182.116,14.509,14.509,0,0,1,3.919,1.5V263.064H143.7Zm-2.611,15.318h5.222a.85.85,0,0,1,.872.825v0a.85.85,0,0,1-.872.825h-5.222a.849.849,0,0,1-.872-.825v0A.849.849,0,0,1,141.088,284.146Zm16.279-8.471a.9.9,0,0,1-.616.242H141.088a.849.849,0,0,1-.872-.825v0a.849.849,0,0,1,.872-.825h15.669a.85.85,0,0,1,.872.825A.8.8,0,0,1,157.367,275.674Zm-9.061-4.463a.894.894,0,0,1,.618-.244h7.832a.85.85,0,0,1,.872.825.8.8,0,0,1-.258.585.892.892,0,0,1-.618.244H148.92a.849.849,0,0,1-.872-.825A.8.8,0,0,1,148.306,271.211Z" transform="translate(-134.995 -261.416)"/>
  <path id="Path_428" data-name="Path 428" className="feed-2" d="M142.139,264.476l-3.991,3.777h3.991Z" transform="translate(-135.176 -261.662)"/>
  <path id="Path_429" data-name="Path 429" className="feed-3" d="M157.262,276.639H141.6a.85.85,0,0,0-.872.824v0a.849.849,0,0,0,.872.825h15.663a.9.9,0,0,0,.616-.242.8.8,0,0,0,.255-.583A.85.85,0,0,0,157.262,276.639Z" transform="translate(-135.507 -263.789)"/>
  <path id="Path_430" data-name="Path 430" className="feed-3" d="M158.134,281.457a.85.85,0,0,0-.872-.825H141.6a.85.85,0,0,0-.872.824v0a.849.849,0,0,0,.872.825h11.688l0,0a1.787,1.787,0,0,1,1.237-.488,14.493,14.493,0,0,1,3.168.366h0a.882.882,0,0,0,.182-.116A.8.8,0,0,0,158.134,281.457Z" transform="translate(-135.507 -264.488)"/>
  <path id="Path_431" data-name="Path 431" className="feed-3" d="M141.6,286.274h11.185v-1.65H141.6a.85.85,0,0,0-.872.824v0A.849.849,0,0,0,141.6,286.274Z" transform="translate(-135.507 -265.185)"/>
  <path id="Path_432" data-name="Path 432" className="feed-3" d="M146.821,288.617H141.6a.85.85,0,0,0-.872.824v0a.849.849,0,0,0,.872.825h5.222a.85.85,0,0,0,.872-.825v0A.849.849,0,0,0,146.821,288.617Z" transform="translate(-135.507 -265.882)"/>
  <path id="Path_433" data-name="Path 433" className="feed-3" d="M150.582,274.3h7.832a.892.892,0,0,0,.618-.244.8.8,0,0,0,.254-.581.85.85,0,0,0-.872-.825h-7.832a.891.891,0,0,0-.614.24.8.8,0,0,0-.258.585A.849.849,0,0,0,150.582,274.3Z" transform="translate(-136.657 -263.091)"/>
  <path id="Path_434" data-name="Path 434" className="feed-3" d="M160.9,293.193v.844H142.844v-.045h-6.365V269.3h6.092a.874.874,0,0,0,.8-.5.786.786,0,0,0,.07-.323v-5.764h17.406v17.523a13.933,13.933,0,0,1,1.739,1.135V261.891a.85.85,0,0,0-.872-.825H142.572a.905.905,0,0,0-.616.241l-6.962,6.591a.8.8,0,0,0-.255.583v26.336a.832.832,0,0,0,.629.79h27.249v-2.629a1.784,1.784,0,0,1-.833.215ZM141.7,263.88v3.777h-3.991Z" transform="translate(-134.74 -261.066)"/>
  <g id="Group_735" data-name="Group 735" transform="translate(19.009 18.954)">
    <path id="Path_435" data-name="Path 435" className="feed-4" d="M165.383,287.658c-.079-.079-.15-.164-.232-.241a11.9,11.9,0,0,0-1.508-1.2h0a12.593,12.593,0,0,0-7.088-2.179h0v2.2a10.1,10.1,0,0,1,7.088,2.88h0a9.335,9.335,0,0,1,1.768,2.419,8.871,8.871,0,0,1,.979,4.024h2.331A11.163,11.163,0,0,0,165.383,287.658Z" transform="translate(-156.543 -284.036)"/>
    <path id="Path_436" data-name="Path 436" className="feed-4" d="M163.685,296.389h.893a7.256,7.256,0,0,0-.936-3.557v0a7.617,7.617,0,0,0-1.421-1.817,8.24,8.24,0,0,0-5.672-2.23h0v2.2h.006a5.838,5.838,0,0,1,4.022,1.583,5.236,5.236,0,0,1,1.671,3.817v0Z" transform="translate(-156.542 -284.866)"/>
    <path id="Path_437" data-name="Path 437" className="feed-4" d="M158.158,294.287a1.66,1.66,0,0,0-1.142.449,1.459,1.459,0,0,0-.01,2.145l.01.01a1.683,1.683,0,0,0,2.285,0,1.469,1.469,0,0,0,0-2.159,1.662,1.662,0,0,0-1.138-.445Z" transform="translate(-156.541 -285.828)"/>
  </g>
</svg>
