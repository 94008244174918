import * as React from 'react'

export const Reserved = (props: any) =>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width={props.svgwidth} height={props.svgheight} viewBox="0 0 840.000000 543.000000"
 preserveAspectRatio="xMidYMid meet">

  <g transform="translate(0.000000,543.000000) scale(0.100000,-0.100000)"
  fill="#72cac2" stroke="none">
    <path d="M2963 5019 c-35 -10 -80 -58 -89 -95 -16 -61 -2 -81 361 -516 267
    -320 363 -429 387 -437 22 -8 395 -11 1239 -11 l1209 0 0 -795 0 -795 -413 0
    c-469 0 -482 -2 -561 -71 -84 -74 -112 -167 -82 -271 14 -50 53 -100 349 -454
    183 -220 481 -577 662 -794 381 -457 406 -485 460 -513 57 -30 174 -30 230 0
    57 30 74 50 556 628 239 286 538 645 666 798 193 231 235 288 248 330 19 64
    19 90 -1 158 -12 40 -29 67 -68 105 -82 83 -87 84 -573 84 l-413 0 -2 1288 -3
    1287 -23 32 c-14 20 -37 36 -60 43 -48 13 -4033 12 -4079 -1z"/>
    <path d="M1711 5006 c-70 -25 -21 31 -1272 -1467 -142 -171 -209 -259 -217
    -287 -30 -101 -1 -216 72 -279 80 -71 55 -68 538 -71 l438 -3 2 -1282 3 -1282
    22 -36 c12 -19 34 -41 48 -47 20 -9 524 -12 2051 -12 2212 0 2068 -4 2113 58
    28 39 28 105 1 150 -29 48 -665 807 -697 832 -26 20 -33 20 -1249 20 l-1224 0
    0 800 0 800 428 0 c427 0 427 0 477 24 60 28 127 99 143 154 15 50 15 124 1
    173 -7 21 -48 83 -93 137 -235 289 -1285 1542 -1315 1569 -61 57 -185 80 -270
    49z"/>
  </g>
</svg>
