import * as React from 'react'

export const Reports = (props: any) =>
<svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width={props.svgwidth} height={props.svgheight} viewBox="0 0 512.000000 512.000000"
 preserveAspectRatio="xMidYMid meet">

  <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
  fill="#000000" stroke="none">
    <path d="M688 5099 c-23 -12 -46 -35 -58 -59 -20 -39 -20 -55 -20 -2180 0
      -2126 0 -2141 20 -2180 13 -26 34 -47 60 -60 36 -18 58 -20 280 -20 l240 0 0
      -240 c0 -222 2 -244 20 -280 13 -26 34 -47 60 -60 39 -20 56 -20 1570 -20
      1514 0 1531 0 1570 20 26 13 47 34 60 60 20 39 20 54 20 2175 0 2121 0 2136
      -20 2175 -13 26 -34 47 -60 60 -36 18 -58 20 -280 20 l-240 0 0 245 c0 227 -2
      249 -20 285 -13 26 -34 47 -60 60 -39 20 -55 20 -1572 20 -1507 -1 -1534 -1
      -1570 -21z m2922 -2239 l0 -1960 -1350 0 -1350 0 0 1960 0 1960 1350 0 1350 0
      0 -1960z m600 -605 l0 -1955 -1350 0 -1350 0 0 150 0 150 1140 0 c1124 0 1141
      0 1180 20 26 13 47 34 60 60 20 39 20 55 20 1785 l0 1745 150 0 150 0 0 -1955z"/>
    <path d="M1288 4189 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 38 -19
      58 -20 520 -20 462 0 482 1 520 20 45 23 80 80 80 130 0 50 -35 107 -80 130
      -38 19 -57 20 -522 20 -461 -1 -485 -2 -520 -21z"/>
    <path d="M1288 3589 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 38 -20
      57 -20 970 -20 913 0 932 0 970 20 45 23 80 80 80 130 0 50 -35 107 -80 130
      -38 20 -56 20 -972 20 -909 -1 -934 -1 -970 -21z"/>
    <path d="M1288 2989 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 38 -20
      57 -20 970 -20 913 0 932 0 970 20 45 23 80 80 80 130 0 50 -35 107 -80 130
      -38 20 -56 20 -972 20 -909 -1 -934 -1 -970 -21z"/>
    <path d="M1288 2389 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 38 -20
      57 -20 970 -20 913 0 932 0 970 20 45 23 80 80 80 130 0 50 -35 107 -80 130
      -38 20 -56 20 -972 20 -909 -1 -934 -1 -970 -21z"/>
    <path d="M2488 1789 c-43 -22 -78 -81 -78 -129 0 -50 35 -107 80 -130 37 -19
      58 -20 370 -20 312 0 333 1 370 20 45 23 80 80 80 130 0 50 -35 107 -80 130
      -37 19 -58 20 -372 20 -312 -1 -335 -2 -370 -21z"/>
  </g>
</svg>
