import * as React from 'react'
import './sources.css'

export const Sources = (props: any) =>
  <svg width={props.svgwidth} height={props.svgheight} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="44" height="44" fill="white"/>
      <path
        d="M5.90983 6.32165C4.85644 6.627 4.24826 7.7317 4.55361 8.78509L7.32165 18.3342C7.627 19.3876 8.73169 19.9958 9.78508 19.6905L19.3342 16.9224C20.3876 16.6171 20.9958 15.5124 20.6905 14.459L17.9224 4.90983C17.6171 3.85644 16.5124 3.24826 15.459 3.55361L5.90983 6.32165ZM7.76436 9.14832C7.64931 8.75143 7.87605 8.33958 8.27294 8.22453C12.3612 7.03946 16.6337 9.39166 17.8188 13.4799C17.9338 13.8768 17.7071 14.2886 17.3102 14.4037C16.9133 14.5187 16.5014 14.292 16.3864 13.8951C15.4305 10.5977 11.9856 8.70107 8.68815 9.6569C8.29126 9.77195 7.8794 9.54521 7.76436 9.14832ZM8.59477 12.0131C8.47972 11.6162 8.70647 11.2043 9.10335 11.0893C11.607 10.3635 14.2283 11.8066 14.954 14.3103C15.0691 14.7072 14.8423 15.119 14.4454 15.2341C14.0485 15.3491 13.6367 15.1224 13.5216 14.7255C13.0251 13.0126 11.2314 12.0251 9.51856 12.5216C9.12167 12.6367 8.70982 12.41 8.59477 12.0131ZM9.63278 15.594C9.55937 15.3407 9.58957 15.0687 9.71674 14.8377C9.84391 14.6067 10.0576 14.4357 10.3109 14.3623C10.5642 14.2889 10.8362 14.3191 11.0672 14.4462C11.2982 14.5734 11.4692 14.7871 11.5426 15.0404C11.616 15.2936 11.5858 15.5657 11.4587 15.7967C11.3315 16.0277 11.1178 16.1987 10.8645 16.2721C10.6112 16.3455 10.3392 16.3153 10.1082 16.1881C9.87721 16.061 9.7062 15.8473 9.63278 15.594Z"
        fill="#FD952F"/>
      <g clipPath="url(#clip0_2_208)">
          <path
            d="M39.9263 30.5412C39.9324 30.1987 39.8483 29.8605 39.6824 29.5601C39.5166 29.2597 39.2747 29.0076 38.9809 28.829C37.9062 28.1727 33.2096 26.8064 33.2096 26.8064C33.2096 26.8064 28.5101 25.4497 27.251 25.4271C26.9072 25.4202 26.5679 25.5032 26.2668 25.6678C25.9657 25.8323 25.7132 26.0727 25.5347 26.3649C24.5812 28.3265 23.5293 31.432 23.4524 33.559C23.4463 33.9014 23.5304 34.2396 23.6963 34.54C23.8621 34.8404 24.104 35.0925 24.3977 35.2711C25.4725 35.9274 30.1692 37.2937 30.1692 37.2937C30.1692 37.2937 34.8658 38.66 36.1248 38.6826C36.4686 38.6895 36.8079 38.6065 37.109 38.442C37.4102 38.2774 37.6626 38.0371 37.8411 37.7448C38.8148 35.786 39.844 32.6759 39.9263 30.5412Z"
            fill="#FF0000"/>
          <path d="M29.5362 33.8604L34.0859 32.7472L30.8433 29.3671L29.5362 33.8604Z" fill="white"/>
      </g>
      <g clipPath="url(#clip1_2_208)">
          <path
            d="M39.71 11.2789C39.8597 7.00616 36.5174 3.42097 32.2447 3.2712C27.972 3.12142 24.3868 6.46377 24.237 10.7365C24.1016 14.598 26.8186 17.8978 30.4967 18.6078L30.6863 13.2016L28.7219 13.1328L28.8003 10.8965L30.7647 10.9653L30.8244 9.26087C30.8924 7.32191 32.0849 6.29138 33.8521 6.35333C34.6986 6.383 35.5786 6.56513 35.5786 6.56513L35.5119 8.46904L34.5363 8.43484C33.5753 8.40115 33.2546 8.98702 33.2332 9.59887L33.1823 11.0501L35.328 11.1253L34.9066 13.3496L33.1039 13.2864L32.9144 18.6926C36.6333 18.2419 39.5746 15.1404 39.71 11.2789Z"
            fill="#1877F2"/>
          <path
            d="M34.9066 13.3496L35.328 11.1253L33.1823 11.0501L33.2332 9.59887C33.2546 8.98697 33.5753 8.40116 34.5363 8.43485L35.5119 8.46904L35.5786 6.56514C35.5786 6.56514 34.6986 6.383 33.8521 6.35333C32.085 6.29138 30.8924 7.32192 30.8244 9.26088L30.7647 10.9653L28.8003 10.8965L28.7219 13.1328L30.6863 13.2017L30.4968 18.6078C30.8945 18.6845 31.2975 18.7301 31.7023 18.7442C32.1071 18.7585 32.5123 18.7412 32.9144 18.6926L33.1039 13.2864L34.9066 13.3496Z"
            fill="white"/>
      </g>
      <g clipPath="url(#clip2_2_208)">
          <path
            d="M17.56 24.7018L10.1749 24.2148C8.37542 24.0962 6.8205 25.4587 6.70184 27.2582L6.21486 34.6433C6.0962 36.4428 7.45874 37.9977 9.25818 38.1164L16.6434 38.6033C18.4428 38.722 19.9977 37.3595 20.1164 35.56L20.6033 28.1748C20.722 26.3754 19.3595 24.8205 17.56 24.7018Z"
            fill="white"/>
          <path
            d="M17.56 24.7018L10.1749 24.2148C8.37542 24.0962 6.8205 25.4587 6.70184 27.2582L6.21486 34.6433C6.0962 36.4428 7.45874 37.9977 9.25818 38.1164L16.6434 38.6033C18.4428 38.722 19.9977 37.3595 20.1164 35.56L20.6033 28.1748C20.722 26.3754 19.3595 24.8205 17.56 24.7018Z"
            fill="#0A66C2"/>
          <path
            d="M16.1677 36.4823L17.7572 36.5871C17.8148 36.5909 17.8715 36.5717 17.9149 36.5336C17.9584 36.4956 17.9849 36.4419 17.9887 36.3843L18.211 33.0261C18.3267 31.2709 18.0374 29.8968 15.9863 29.7616C15.2085 29.6812 14.4466 30.0346 14.0056 30.6796C14.0034 30.6828 14.0003 30.6851 13.9967 30.6863C13.9931 30.6875 13.9892 30.6875 13.9856 30.6862C13.982 30.685 13.9789 30.6826 13.9768 30.6795C13.9747 30.6763 13.9736 30.6726 13.9739 30.6688L14.0171 30.0126C14.0209 29.955 14.0017 29.8983 13.9636 29.8548C13.9256 29.8114 13.8718 29.7849 13.8142 29.7811L12.3059 29.6816C12.2483 29.6778 12.1915 29.6971 12.1481 29.7351C12.1047 29.7732 12.0781 29.8269 12.0743 29.8845L11.6732 35.9675C11.6694 36.0251 11.6887 36.0819 11.7267 36.1253C11.7648 36.1687 11.8185 36.1952 11.8761 36.199L13.4654 36.3038C13.523 36.3076 13.5798 36.2884 13.6232 36.2504C13.6666 36.2123 13.6932 36.1586 13.697 36.101L13.8952 33.0941C13.9513 32.2438 14.1669 31.4311 15.221 31.5006C16.26 31.5691 16.2093 32.5429 16.1595 33.2987L15.9648 36.2508C15.961 36.3084 15.9803 36.3651 16.0183 36.4086C16.0564 36.452 16.1101 36.4785 16.1677 36.4823ZM8.76667 27.374C8.72419 28.0183 9.21961 28.5834 9.86391 28.6258C10.5081 28.6683 11.0731 28.1728 11.1156 27.5286C11.1579 26.8845 10.6628 26.3195 10.0186 26.277C9.37422 26.2345 8.80915 26.7298 8.76667 27.374ZM8.5786 35.9819L10.1701 36.0868C10.2277 36.0906 10.2844 36.0714 10.3278 36.0333C10.3713 35.9953 10.3978 35.9415 10.4016 35.8839L10.8027 29.8007C10.8065 29.7431 10.7873 29.6863 10.7492 29.6429C10.7112 29.5995 10.6574 29.5729 10.5998 29.5691L9.00838 29.4642C8.95077 29.4604 8.89402 29.4796 8.85059 29.5177C8.80717 29.5557 8.78065 29.6095 8.77685 29.6671L8.37571 35.7503C8.37191 35.808 8.39115 35.8647 8.4292 35.9081C8.46725 35.9516 8.52099 35.9781 8.5786 35.9819Z"
            fill="white"/>
      </g>
      <defs>
          <clipPath id="clip0_2_208">
              <rect width="15.661" height="10.9627" fill="white"
                    transform="translate(25.6985 24.6214) rotate(16.2203)"/>
          </clipPath>
          <clipPath id="clip1_2_208">
              <rect width="15.4825" height="15.4825" fill="white" transform="translate(24.5082 3) rotate(2.00763)"/>
          </clipPath>
          <clipPath id="clip2_2_208">
              <rect width="13.9317" height="13.9317" fill="white" transform="translate(6.91669 24) rotate(3.77267)"/>
          </clipPath>
      </defs>
  </svg>
