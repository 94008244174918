import * as React from 'react'
import './filters.css'

export const Sections = (props: any) =>
  <svg width={props.svgwidth} height={props.svgheight} viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="44" height="44" fill="white"/>
    <path
      d="M34.5 9H9.5C8.11929 9 7 9.41041 7 9.91667V19.0833C7 19.5896 8.11929 20 9.5 20H34.5C35.8807 20 37 19.5896 37 19.0833V9.91667C37 9.41041 35.8807 9 34.5 9Z"
      fill="#72CAC2" fill-opacity="0.5" stroke="#1E4E69" strokeWidth="2" strokeLinejoin="round"/>
    <path
      d="M23.5 24H8.5C7.67157 24 7 24.4104 7 24.9167V34.0833C7 34.5896 7.67157 35 8.5 35H23.5C24.3284 35 25 34.5896 25 34.0833V24.9167C25 24.4104 24.3284 24 23.5 24Z"
      fill="#FBE57F" stroke="#1E4E69" strokeWidth="2" strokeLinejoin="round"/>
    <path
      d="M36.3333 24H29.6667C29.2985 24 29 24.4104 29 24.9167V34.0833C29 34.5896 29.2985 35 29.6667 35H36.3333C36.7015 35 37 34.5896 37 34.0833V24.9167C37 24.4104 36.7015 24 36.3333 24Z"
      fill="#B8E5E1" stroke="#1E4E69" strokeWidth="2" strokeLinejoin="round"/>
  </svg>
