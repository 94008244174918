import * as React from 'react'
import './rasalogo.css'

export const RasaLogo = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" width="80" height="17.068" viewBox="0 0 80 17.068">
  <g id="Group_5226" data-name="Group 5226" transform="translate(-745 -192)">
    <g id="Group_5225" data-name="Group 5225" transform="translate(745 192)">
      <path id="Path_2" data-name="Path 2" className="rlogo-1" d="M178.742,29.92l-3.728-.763c-1.013-.222-1.2-.669-1.2-1.114,0-.573.443-1.242,1.8-1.242a2.464,2.464,0,0,1,2.654,1.878l4.612-.924c-.632-2.771-3.254-4.584-7.2-4.584-4.011,0-6.823,2.133-6.823,5.415,0,2.515,1.674,4.107,4.612,4.648l3.223.636c1.169.222,1.58.636,1.58,1.177,0,.7-.726,1.273-2.054,1.273-1.359,0-2.591-.51-2.811-1.878l-4.928,1.018c.537,3.279,3.726,4.648,7.456,4.648,4.169,0,7.2-1.846,7.2-5.38C183.133,32.368,181.742,30.555,178.742,29.92Z" transform="translate(-137.828 -23.056)"/>
      <path id="Path_3" data-name="Path 3" className="rlogo-1" d="M259.508,24.88a7.109,7.109,0,0,0-4.928-1.75c-4.2,0-7.74,3.438-7.74,8.469,0,5.062,3.537,8.469,7.74,8.469a7.118,7.118,0,0,0,4.928-1.75l.008,1.754h5.056l0-16.951h-5.062ZM255.62,35.736A3.813,3.813,0,0,1,251.8,31.6a3.831,3.831,0,0,1,3.823-4.138,4.147,4.147,0,0,1,0,8.278Z" transform="translate(-200.829 -23.016)"/>
      <path id="Path_5" data-name="Path 5" className="rlogo-1" d="M84.138,24.9a7.109,7.109,0,0,0-4.928-1.75c-4.2,0-7.74,3.438-7.74,8.469,0,5.062,3.537,8.469,7.74,8.469a7.118,7.118,0,0,0,4.928-1.75l.006,1.768,5.048-.014,0-16.941H84.142ZM80.25,35.758a3.813,3.813,0,0,1-3.823-4.14A3.831,3.831,0,0,1,80.25,27.48a4.147,4.147,0,0,1,0,8.278Z" transform="translate(-59.803 -23.038)"/>
      <path id="Path_4" data-name="Path 4" className="rlogo-1" d="M354.99,89.314a2.117,2.117,0,1,0,2.227-2.162c-.033,0-.067,0-.1,0A2.1,2.1,0,0,0,354.99,89.314Z" transform="translate(-287.757 -74.494)"/>
      <path id="Path_11674" data-name="Path 11674" className="rlogo-1" d="M17.362,31.027h.014a4.138,4.138,0,0,1,3.809-4.091,3.774,3.774,0,0,1,1.9.475v-4.77a7.119,7.119,0,0,0-.917-.047,7.119,7.119,0,0,0-4.9,1.821l-.03-1.76-5.062.073.008,16.878,5.182,0V31.027Z" transform="translate(-12.17 -22.592)"/>
      <rect id="Rectangle_2" data-name="Rectangle 2" className="rlogo-1" width="3.48" height="7.315" transform="matrix(0.007, -1, 1, 0.007, 72.662, 16.613)"/>
      <path id="Path_4-2" data-name="Path 4" className="rlogo-1" d="M356.779,28.981a6.208,6.208,0,1,0,6.53-5.936c-.1,0-.2-.006-.293-.006A5.959,5.959,0,0,0,356.779,28.981Zm9.245-.287a2.934,2.934,0,0,1-2.821,3.075,3,3,0,0,1-3.282-2.642,2.934,2.934,0,0,1,2.821-3.075c.083-.006.167-.008.252-.008a2.844,2.844,0,0,1,3.03,2.617Z" transform="translate(-289.194 -22.951)"/>
    </g>
  </g>
</svg>
