import * as React from 'react'
import './salesforce.css'

export const SalesforceIcon = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" className={`system-icon ${props.className}`} viewBox="0 0 74 56">
    <defs>
        <clipPath id="clip-path">
            <path id="Rectangle_2001" d="M0 0h74v56H0z" className="sf-1" data-name="Rectangle 2001" transform="translate(438 336)"/>
        </clipPath>
    </defs>
    <g id="Mask_Group_17" className="sf-2" data-name="Mask Group 17" transform="translate(-438 -336)">
        <g id="Group_5277" data-name="Group 5277" transform="translate(443.295 341.295)">
            <path id="Path_11955" d="M26.9 5.039a11.274 11.274 0 0 1 8.159-3.5 11.391 11.391 0 0 1 9.926 5.881A13.715 13.715 0 0 1 50.6 6.228a13.992 13.992 0 0 1 0 27.983 13.758 13.758 0 0 1-2.732-.272 10.139 10.139 0 0 1-13.285 4.173 11.568 11.568 0 0 1-21.5-.523 10.611 10.611 0 0 1-2.207.23A10.873 10.873 0 0 1 5.5 17.569a12.494 12.494 0 0 1 21.4-12.53" className="sf-3" data-name="Path 11955" transform="translate(-.128 -.128)"/>
            <path id="Path_11956" d="M37.127 77.332c-.04.105.015.128.027.146a3.726 3.726 0 0 0 .368.222 3.918 3.918 0 0 0 1.924.45c1.32 0 2.139-.7 2.139-1.832V76.3c0-1.045-.925-1.424-1.793-1.7l-.113-.037c-.654-.213-1.219-.4-1.219-.827v-.023c0-.368.33-.64.841-.64a3.807 3.807 0 0 1 1.677.429s.127.083.174-.041c.026-.066.246-.658.269-.722a.123.123 0 0 0-.064-.148 3.742 3.742 0 0 0-1.891-.508h-.132a1.844 1.844 0 0 0-2.051 1.775v.022c0 1.1.93 1.46 1.8 1.709l.14.043c.635.2 1.182.363 1.182.81v.022c0 .409-.355.713-.929.713a3.12 3.12 0 0 1-1.7-.49c-.093-.054-.147-.093-.218-.136a.1.1 0 0 0-.173.059l-.26.722z" className="sf-4" data-name="Path 11956" transform="translate(-27.808 -53.98)"/>
            <path id="Path_11957" d="M113.926 77.332c-.041.105.015.128.027.146a3.726 3.726 0 0 0 .368.222 3.918 3.918 0 0 0 1.924.45c1.32 0 2.139-.7 2.139-1.832V76.3c0-1.045-.925-1.424-1.793-1.7l-.113-.037c-.654-.213-1.219-.4-1.219-.827v-.023c0-.368.33-.64.841-.64a3.806 3.806 0 0 1 1.677.429s.127.083.174-.041c.026-.066.246-.658.268-.722a.123.123 0 0 0-.064-.148 3.741 3.741 0 0 0-1.891-.508h-.132a1.843 1.843 0 0 0-2.051 1.775v.022c0 1.1.93 1.46 1.8 1.709l.14.043c.635.2 1.183.363 1.183.81v.022c0 .409-.357.713-.93.713a3.119 3.119 0 0 1-1.7-.49c-.093-.054-.148-.092-.217-.136a.113.113 0 0 0-.174.059l-.26.722z" className="sf-4" data-name="Path 11957" transform="translate(-85.287 -53.98)"/>
            <path id="Path_11958" d="M154.151 75.171a2.715 2.715 0 0 1-.354 1.5 1.184 1.184 0 0 1-1.072.523 1.169 1.169 0 0 1-1.068-.523 3.383 3.383 0 0 1 0-2.99 1.175 1.175 0 0 1 1.068-.518 1.19 1.19 0 0 1 1.073.518 2.7 2.7 0 0 1 .353 1.492m1.1-1.184a2.651 2.651 0 0 0-.5-.957 2.422 2.422 0 0 0-.846-.643 3.067 3.067 0 0 0-2.362 0 2.434 2.434 0 0 0-.847.643 2.7 2.7 0 0 0-.5.957 4.427 4.427 0 0 0 0 2.368 2.667 2.667 0 0 0 .5.957 2.361 2.361 0 0 0 .846.638 3.134 3.134 0 0 0 2.362 0 2.364 2.364 0 0 0 .846-.638 2.651 2.651 0 0 0 .5-.957 4.4 4.4 0 0 0 0-2.368" className="sf-4" data-name="Path 11958" transform="translate(-112.322 -54.032)"/>
            <path id="Path_11959" d="M194.116 77a.1.1 0 0 0-.14-.067 2.514 2.514 0 0 1-.512.147 3.983 3.983 0 0 1-.6.043 1.678 1.678 0 0 1-1.26-.472 2.1 2.1 0 0 1-.473-1.506 2.335 2.335 0 0 1 .422-1.451 1.438 1.438 0 0 1 1.223-.538 3.665 3.665 0 0 1 1.164.167s.087.038.128-.076c.1-.268.168-.459.27-.753a.1.1 0 0 0-.068-.129 4.277 4.277 0 0 0-.735-.185 5.5 5.5 0 0 0-.827-.056 3 3 0 0 0-1.228.236 2.544 2.544 0 0 0-.887.643 2.733 2.733 0 0 0-.536.957 3.806 3.806 0 0 0-.18 1.186 3.172 3.172 0 0 0 .733 2.193 2.788 2.788 0 0 0 2.175.818 4.478 4.478 0 0 0 1.56-.279.1.1 0 0 0 .045-.131l-.27-.747z" className="sf-4" data-name="Path 11959" transform="translate(-142.14 -54.015)"/>
            <path id="Path_11960" d="M210.522 74.56a2.167 2.167 0 0 1 .3-.88 1.187 1.187 0 0 1 1.066-.541 1.157 1.157 0 0 1 1.045.541 1.841 1.841 0 0 1 .247.88zm3.709-.78a2.322 2.322 0 0 0-.477-.873 2.189 2.189 0 0 0-.708-.54 2.723 2.723 0 0 0-1.069-.216 2.909 2.909 0 0 0-1.231.239 2.448 2.448 0 0 0-.859.655 2.684 2.684 0 0 0-.5.968 4.181 4.181 0 0 0-.164 1.19 3.987 3.987 0 0 0 .17 1.19 2.394 2.394 0 0 0 1.478 1.569 3.752 3.752 0 0 0 1.354.219 3.9 3.9 0 0 0 1.878-.373c.041-.023.081-.063.031-.179l-.244-.683a.107.107 0 0 0-.14-.064 3.6 3.6 0 0 1-1.531.275 1.526 1.526 0 0 1-1.71-1.679h3.729a.11.11 0 0 0 .108-.1 4 4 0 0 0-.111-1.607z" className="sf-4" data-name="Path 11960" transform="translate(-156.618 -54.032)"/>
            <path id="Path_11961" d="M91.806 74.56a2.174 2.174 0 0 1 .3-.88 1.187 1.187 0 0 1 1.066-.541 1.159 1.159 0 0 1 1.046.541 1.849 1.849 0 0 1 .247.88zm3.708-.78a2.317 2.317 0 0 0-.477-.873 2.188 2.188 0 0 0-.707-.54 2.723 2.723 0 0 0-1.069-.216 2.912 2.912 0 0 0-1.231.239 2.445 2.445 0 0 0-.859.655 2.683 2.683 0 0 0-.5.968A4.218 4.218 0 0 0 90.5 75.2a3.979 3.979 0 0 0 .17 1.19 2.394 2.394 0 0 0 1.478 1.569 3.751 3.751 0 0 0 1.354.219 3.9 3.9 0 0 0 1.878-.373c.041-.023.081-.063.031-.179l-.243-.683a.108.108 0 0 0-.141-.064 3.6 3.6 0 0 1-1.532.275 1.526 1.526 0 0 1-1.709-1.679h3.729a.11.11 0 0 0 .108-.1 3.989 3.989 0 0 0-.112-1.607z" className="sf-4" data-name="Path 11961" transform="translate(-67.768 -54.032)"/>
            <path id="Path_11962" d="M59.075 76.983a.754.754 0 0 1-.215-.221.9.9 0 0 1-.111-.485.816.816 0 0 1 .333-.722 1.672 1.672 0 0 1 1.081-.269 7.629 7.629 0 0 1 1.012.086v1.7a7.552 7.552 0 0 1-1.007.134 1.675 1.675 0 0 1-1.093-.218m1.484-2.62a8.221 8.221 0 0 0-.582-.018 3.544 3.544 0 0 0-.918.118 2.4 2.4 0 0 0-.779.361 1.739 1.739 0 0 0-.74 1.474 1.948 1.948 0 0 0 .17.852 1.535 1.535 0 0 0 .487.583 2.037 2.037 0 0 0 .76.329 4.454 4.454 0 0 0 .987.1 6.714 6.714 0 0 0 1.133-.094c.366-.062.815-.153.939-.181s.261-.067.261-.067a.117.117 0 0 0 .085-.122v-3.41a2.087 2.087 0 0 0-.593-1.646 2.536 2.536 0 0 0-1.713-.516 6.134 6.134 0 0 0-1 .093 3.6 3.6 0 0 0-1.15.42.116.116 0 0 0-.033.148l.264.709a.094.094 0 0 0 .122.06.456.456 0 0 0 .061-.03 3.783 3.783 0 0 1 1.625-.378 1.5 1.5 0 0 1 .922.241c.2.156.307.391.307.888v.157c-.321-.046-.615-.072-.615-.072" className="sf-4" data-name="Path 11962" transform="translate(-43.097 -54.014)"/>
            <path id="Path_11963" d="M178.855 72.643a.1.1 0 0 0-.056-.134 3.219 3.219 0 0 0-.625-.107 1.858 1.858 0 0 0-.961.155 1.7 1.7 0 0 0-.631.465v-.454a.108.108 0 0 0-.107-.114h-.956a.108.108 0 0 0-.107.114v5.562a.114.114 0 0 0 .114.113h.98a.113.113 0 0 0 .113-.113v-2.779a3.255 3.255 0 0 1 .124-.979 1.41 1.41 0 0 1 .326-.549 1.143 1.143 0 0 1 .461-.277 1.791 1.791 0 0 1 .5-.071 2.094 2.094 0 0 1 .41.05c.071.008.112-.036.136-.1.064-.171.246-.681.281-.783" className="sf-4" data-name="Path 11963" transform="translate(-131.316 -54.215)"/>
            <path id="Path_11964" d="M133.4 62.754a3.217 3.217 0 0 0-.369-.088 2.917 2.917 0 0 0-.507-.038 2.135 2.135 0 0 0-1.579.566 3.079 3.079 0 0 0-.75 1.692l-.046.252h-.849a.125.125 0 0 0-.125.108l-.138.776c-.01.073.022.12.121.12h.824l-.836 4.667a5.739 5.739 0 0 1-.224.919 1.882 1.882 0 0 1-.26.531.692.692 0 0 1-.341.263 1.389 1.389 0 0 1-.439.063 1.642 1.642 0 0 1-.3-.033.88.88 0 0 1-.2-.065.1.1 0 0 0-.135.06l-.277.758a.113.113 0 0 0 .058.149 3.04 3.04 0 0 0 .341.1 2.265 2.265 0 0 0 .546.051 2.736 2.736 0 0 0 .915-.142 1.733 1.733 0 0 0 .689-.48 2.463 2.463 0 0 0 .484-.835 7.067 7.067 0 0 0 .328-1.252l.84-4.754h1.229a.125.125 0 0 0 .125-.109l.139-.775c.009-.074-.022-.12-.122-.12h-1.192a5.151 5.151 0 0 1 .2-.841 1.1 1.1 0 0 1 .261-.4.787.787 0 0 1 .313-.194 1.3 1.3 0 0 1 .4-.057 1.546 1.546 0 0 1 .306.03c.115.025.161.037.191.047.122.037.138 0 .162-.058l.285-.783a.1.1 0 0 0-.069-.13" className="sf-4" data-name="Path 11964" transform="translate(-95.057 -46.905)"/>
            <path id="Path_11965" d="M82.744 71.177a.108.108 0 0 1-.107.113h-.989a.107.107 0 0 1-.106-.113v-7.959a.107.107 0 0 1 .106-.113h.989a.108.108 0 0 1 .107.113v7.959z" className="sf-4" data-name="Path 11965" transform="translate(-61.061 -47.262)"/>
        </g>
    </g>
</svg>
