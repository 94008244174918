import * as React from 'react'
import './shopify.css'

export const ShopifyIcon = (props: any) =>
<svg xmlns="http://www.w3.org/2000/svg" className={`system-icon ${props.className}`} viewBox="0 0 74 56">
    <defs>
        <clipPath id="clip-path">
            <path id="Rectangle_2001" d="M0 0h74v56H0z" className="shopify-1" data-name="Rectangle 2001" transform="translate(438 336)"/>
        </clipPath>
    </defs>
    <g id="Mask_Group_17" className="shopify-2" data-name="Mask Group 17" transform="translate(-438 -336)">
        <g id="Group_5085" data-name="Group 5085" transform="translate(456 342)">
            <path id="Path_13605" d="M-2302.828-412.2a.417.417 0 0 0-.381-.355c-.159-.013-3.513-.262-3.513-.262s-2.329-2.312-2.585-2.568a1.06 1.06 0 0 0-.949-.121l-1.3.4c-.778-2.239-2.151-4.3-4.566-4.3-.067 0-.135 0-.2.007a2.936 2.936 0 0 0-2.273-1.3c-5.628 0-8.316 7.035-9.158 10.61l-3.939 1.221c-1.22.384-1.259.418-1.422 1.572-.121.871-3.315 25.568-3.315 25.568l24.886 4.663 13.484-2.917s-4.741-31.998-4.769-32.218zm-10.106-2.478l-2.105.652v-.454a10.308 10.308 0 0 0-.5-3.4c1.245.157 2.076 1.573 2.607 3.207zm-4.15-2.928a10.44 10.44 0 0 1 .571 3.791v.244l-4.35 1.347c.836-3.231 2.406-4.792 3.779-5.378zm-1.673-1.582a1.267 1.267 0 0 1 .721.243c-1.8.849-3.736 2.986-4.553 7.254l-3.437 1.065c.956-3.255 3.226-8.565 7.268-8.565z" className="shopify-3" data-name="Path 13605" transform="translate(2336.437 420.695)"/>
            <path id="Path_13606" d="M-2298.342-411.546c-.159-.013-3.513-.262-3.513-.262s-2.33-2.313-2.584-2.568a.639.639 0 0 0-.359-.167l-1.882 38.488 13.482-2.917-4.764-32.221a.417.417 0 0 0-.38-.353z" className="shopify-4" data-name="Path 13606" transform="translate(2331.571 419.689)"/>
            <path id="Path_13607" d="M-2314.97-402.185l-1.662 4.945a7.647 7.647 0 0 0-3.242-.777c-2.618 0-2.75 1.643-2.75 2.057 0 2.258 5.888 3.124 5.888 8.415 0 4.162-2.64 6.842-6.2 6.842a8.625 8.625 0 0 1-6.457-2.658l1.143-3.779s2.246 1.924 4.141 1.924a1.673 1.673 0 0 0 1.741-1.6v-.085c0-2.947-4.829-3.078-4.829-7.92 0-4.075 2.927-8.018 8.829-8.018a7.26 7.26 0 0 1 3.398.654z" className="shopify-5" data-name="Path 13607" transform="translate(2335.285 417.777)"/>
        </g>
    </g>
</svg>
